import { Tooltip, OverlayTrigger } from "react-bootstrap";
import image from "./images/stretch.jpg";
import stretchCircle from "./images/stretch-circle.PNG";

export default function stretch() {
  const renderTooltipDanielMark = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Daniel Mark
    </Tooltip>
  );

  const renderTooltipPattarai = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Pattarai
    </Tooltip>
  );

  const renderTooltipStretch = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      stretch inc.
    </Tooltip>
  );

  return (
    <div className="card mt-md-0">
      <a href="https://xstackhub.azurewebsites.net" target="top">
        <img src={image} alt="Image" className="card-img-top" />
      </a>
      <div className="card-body d-flex flex-column">
        <div className="d-flex justify-content-between mb-3">
          <div className="text-small d-flex">
            <div className="mr-2">Venture</div>
            <span className="text-muted">
              Dec 2020 <i className="fas fa-angle-double-right"></i> Mar 2021
            </span>
          </div>
          {/* <span className="badge bg-primary-alt text-primary">
            <a
              href="https://github.com/thedanielmark/dannyWebsite"
              target="top"
            >
              <i className="fab fa-github font-weight-bold"></i>
            </a>
          </span> */}
        </div>
        <a href="https://xstackhub.azurewebsites.net" target="top">
          <h4>
            stretch inc.
            <sup className="small ml-1">
              <i className="fas fa-external-link-alt"></i>
            </sup>
          </h4>
        </a>
        <p className="flex-grow-1">
          Web-based scheduling and slot booking software with Firebase SSO,
          Flask APIs and Stripe integration for payments.
        </p>
        <div className="d-flex align-items-center mt-3">
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltipDanielMark}
          >
            <img
              src="https://avatars.githubusercontent.com/u/26758790?s=460&u=429b89cb22392b1695712e237ef3384fd46df9ea&v=4"
              alt="Image"
              className="avatar avatar-sm"
              role="button"
            />
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltipPattarai}
          >
            <div className="border rounded-circle ml-1">
              <img
                src="https://avatars.githubusercontent.com/u/67852035?s=200&v=4"
                alt="Image"
                className="avatar avatar-sm"
                role="button"
              />
            </div>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltipStretch}
          >
            <div className="border rounded-circle ml-1">
              <img
                src={stretchCircle}
                alt="Image"
                className="avatar avatar-sm"
                role="button"
              />
            </div>
          </OverlayTrigger>
        </div>
      </div>
    </div>
  );
}
