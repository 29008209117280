import FancyVideo from "react-videojs-fancybox";

export default function Stats() {
  return (
    <section className="bg-primary-alt has-divider">
      <div className="container pb-0">
        <div className="row justify-content-center text-center mb-6">
          <div className="col-xl-8 col-lg-9">
            <h2 className="display-4 mx-xl-6">Build it from the ground up.</h2>
            <p className="lead">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa.
            </p>
          </div>
        </div>
        <div className="row justify-content-center text-center">
          <div
            className="col-md-4 mb-3 mb-4 mb-md-0 aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay={100}
          >
            <span
              className="display-4 text-primary-2 d-block"
              data-countup
              data-start={1}
              data-end="4.89"
              data-duration={3}
              data-grouping="true"
              data-suffix="/5"
              data-decimal-places={2}
            >
              4.89/5
            </span>
            <span className="h6">Average User Rating</span>
          </div>
          <div
            className="col-md-4 mb-3 mb-4 mb-md-0 aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay={200}
          >
            <span
              className="display-4 text-primary-2 d-block"
              data-countup
              data-start={100000}
              data-end={200000}
              data-duration={3}
              data-grouping="true"
              data-suffix="+"
            >
              200,000+
            </span>
            <span className="h6">Monthly Installs</span>
          </div>
          <div
            className="col-md-4 mb-3 mb-4 mb-md-0 aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay={300}
          >
            <span
              className="display-4 text-primary-2 d-block"
              data-countup
              data-start={1000000}
              data-end={2394421}
              data-duration={3}
              data-grouping="true"
            >
              2,394,421
            </span>
            <span className="h6">Total Downloads</span>
          </div>
        </div>
        <div className="row justify-content-center mt-6 mb-lg-n7">
          <div className="col-xl-8 col-lg-9 layer-1">
            <div className="video-poster shadow-lg rounded mb-3">
              <FancyVideo
                source="https://youtu.be/DULFs16I_z8"
                poster="https://raw.githubusercontent.com/waskito/react-modal-videojs/master/example/public/preview.png"
                id={"sintel"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="divider">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="100%"
          height="96px"
          viewBox="0 0 100 100"
          version="1.1"
          preserveAspectRatio="none"
          className="injected-svg bg-primary-3"
          data-src="assets/img/dividers/divider-1.svg"
        >
          <path d="M0,0 C40,33 66,52 75,52 C83,52 92,33 100,0 L100,100 L0,100 L0,0 Z" />
        </svg>
      </div>
    </section>
  );
}
