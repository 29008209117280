import FadeInWhenVisible from "../../FadeInWhenVisible";

export default function MainContent() {
  return (
    <section className="bg-primary-3 pt-0 has-divider">
      <div className="container">
        <div className="row mb-4 text-light">
          <div className="col">
            <FadeInWhenVisible>
              <h2 className="h1">Need something more detailed?</h2>
            </FadeInWhenVisible>
          </div>
        </div>
        <FadeInWhenVisible>
          <div className="row">
            <div
              className="col-lg-4 d-flex aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <div className="card card-body">
                <div className="flex-grow-1">
                  <div className="h3">Frameworks</div>
                  <p>
                    Having started out as a front-end developer and then making
                    the transition to full-stack, I taught myself multiple
                    JavaScript frameworks and libraries along the way. Some of
                    them are React, Gatsby, Next.js, React Native, jQuery,
                    Cordova, Ionic and Electron.
                  </p>
                </div>
                <a href="#" className="hover-arrow text-orange">
                  Learn More
                </a>
              </div>
            </div>
            <div
              className="col-lg-4 d-flex aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="card card-body">
                <div className="flex-grow-1">
                  <div className="h3">Design</div>
                  <p>
                    Design plays an important role in all my projects. Building
                    an app wasn't always about the functionality or frameworks
                    for me. I place a huge emphasis on user experience so
                    frameworks such as Bootstrap, Material UI and Modernizr were
                    ones that I heavily relied on.
                  </p>
                </div>
                <a href="#" className="hover-arrow text-orange">
                  Learn More
                </a>
              </div>
            </div>
            <div
              className="col-lg-4 d-flex aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <div className="card card-body">
                <div className="flex-grow-1">
                  <div className="h3">Databases</div>
                  <p>
                    Data and state are what personalizes a user's experience on
                    any platform. The choice of database comes down to the
                    application and use-case. Some of the databases I have
                    worked with are MySQL, Oracle Database, PostgreSQL, SQLite,
                    MongoDB &amp; Firestore.
                  </p>
                </div>
                <a href="#" className="hover-arrow text-orange">
                  Learn More
                </a>
              </div>
            </div>
            <div
              className="col-lg-4 d-flex aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <div className="card card-body">
                <div className="flex-grow-1">
                  <div className="h3">Git &amp; CI/CD</div>
                  <p>
                    Apart from my personal projects, Git and version control are
                    an integral part of what I do at Pattarai with the various
                    teams. SCM via Git has also made it easier for us to adopt
                    "Continuous Integration and Deployment" in conjunction with
                    the Azure cloud for all our hosting needs.
                  </p>
                </div>
                <a href="#" className="hover-arrow text-orange">
                  Learn More
                </a>
              </div>
            </div>
            <div
              className="col-lg-4 d-flex aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="card card-body">
                <div className="flex-grow-1">
                  <div className="h3">Cloud &amp; FOSS</div>
                  <p>
                    During my time building MixSpace Cloud Platform, hardcore
                    Unix skills were essential as the cloud heavily relied on
                    various flavours of Linux. OpenStack, Docker and
                    infrastructure automation via Ansible, Puppet and Jenkins
                    were also skills that I had to equip myself with.
                  </p>
                </div>
                <a href="#" className="hover-arrow text-orange">
                  Learn More
                </a>
              </div>
            </div>
            <div
              className="col-lg-4 d-flex aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <div className="card card-body">
                <div className="flex-grow-1">
                  <div className="h3">Soft Skills</div>
                  <p>
                    Effective leadership, communication and conflict resolution
                    were vital to my role as President and Head Dev at Pattarai.
                    My problem solving skills and creativity were also honed
                    through teaching other programmers and architecting
                    solutions to various technical problems.
                  </p>
                </div>
                <a href="#" className="hover-arrow text-orange">
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </FadeInWhenVisible>
      </div>
      <div className="divider">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="100%"
          height="96px"
          viewBox="0 0 100 100"
          version="1.1"
          preserveAspectRatio="none"
          className="injected-svg bg-primary-alt"
          data-src="assets/img/dividers/divider-1.svg"
        >
          <path
            style={{ fill: "#f8f9fa" }}
            d="M0,0 C40,33 66,52 75,52 C83,52 92,33 100,0 L100,100 L0,100 L0,0 Z"
          />
        </svg>
      </div>
    </section>
  );
}
