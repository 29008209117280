import Typewriter from "typewriter-effect";

export default function typewriter() {
  return (
    <Typewriter
      options={{
        strings: ["Wait, there's more you need to know...", "I love animated platypuses", "and baby penguins :P"],
        autoStart: true,
        loop: true,
        changeDelay: 10
      }}
    />
  );
}
