import FadeInWhenVisible from "../../FadeInWhenVisible";

// Importing projects
import XStack from "./xstack";
import MixSpace from "./mixspace";
import KuTumbaFCLanding from "./kutumbafcLanding";
import MixSpaceSSO from "./mixspaceSso";
import KuTumbaFCECommerce from "./kutumbafcECommerce";
import KuTumbaFCAPI from "./kutumbafcAPI";
import XStackHub from "./xstackHub";
import XStackStatus from "./xstackStatus";
import Cortex from "./cortex";
import Stretch from "./stretch";
import Emet from "./emet";
import FringeMessenger from "./fringeMessenger";
import FringeWebsite from "./fringeWebsite";
import We from "./we";
import Blackbox from "./blackbox";
import One from "./one";
import Pattarai from "./pattarai";
import Elevate from "./ElevateLanding";
import Payroll from "./payroll";
import Akash from "./akash";
import Calculator from "./calculator";
import Credity from "./credity";
import Flair from "./flair2k19";
import Frecord from "./frecord";
import Radar from "./radar";
import Hextris from "./hextris";
import WikiAPI from "./wikiAPI";

// Daniel Mark websites
import DanielMarkV1 from "./danielMarkV1";
import DanielMarkV2 from "./danielMarkV2";
import DanielMarkV3 from "./danielMarkV3";
import DanielMarkV4 from "./danielMarkV4";
import DanielMarkV5 from "./danielMarkV5";

export default function MainContent() {
  return (
    <section className="bg-white">
      <div className="container">
        <div className="card-columns">
          <FadeInWhenVisible>
            <XStack></XStack>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <MixSpace></MixSpace>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <MixSpaceSSO></MixSpaceSSO>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <KuTumbaFCLanding></KuTumbaFCLanding>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <KuTumbaFCECommerce></KuTumbaFCECommerce>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <KuTumbaFCAPI></KuTumbaFCAPI>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <XStackHub></XStackHub>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <XStackStatus></XStackStatus>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <Cortex></Cortex>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <Stretch></Stretch>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <Emet></Emet>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <FringeMessenger></FringeMessenger>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <FringeWebsite></FringeWebsite>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <We></We>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <Blackbox></Blackbox>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <One></One>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <Elevate></Elevate>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <Payroll></Payroll>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <Akash></Akash>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <Calculator></Calculator>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <Credity></Credity>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <Flair></Flair>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <Pattarai></Pattarai>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <Frecord></Frecord>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <Radar></Radar>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <Hextris></Hextris>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <WikiAPI></WikiAPI>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <DanielMarkV3></DanielMarkV3>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <DanielMarkV4></DanielMarkV4>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <DanielMarkV2></DanielMarkV2>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <DanielMarkV1></DanielMarkV1>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <DanielMarkV5></DanielMarkV5>
          </FadeInWhenVisible>
        </div>
      </div>
    </section>
  );
}
