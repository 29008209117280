import FadeInWhenVisible from "../../FadeInWhenVisible";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

export default function MainContent() {
  // Tooltip code
  const renderTooltipJS = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      JavaScript
    </Tooltip>
  );

  const renderTooltipPhp = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      PHP
    </Tooltip>
  );

  const renderTooltipReact = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      React
    </Tooltip>
  );

  const renderTooltipNext = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Next.js
    </Tooltip>
  );

  const renderTooltipJQuery = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      jQuery
    </Tooltip>
  );

  const renderTooltipBootstrap = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Bootstrap
    </Tooltip>
  );

  const renderTooltipHTML = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      HTML5
    </Tooltip>
  );

  const renderTooltipCSS = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      CSS3
    </Tooltip>
  );

  const renderTooltipIonic = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Ionic
    </Tooltip>
  );

  const renderTooltipPython = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Python
    </Tooltip>
  );

  const renderTooltipNpm = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      NPM
    </Tooltip>
  );

  const renderTooltipGit = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Git
    </Tooltip>
  );

  const renderTooltipGitHub = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      GitHub
    </Tooltip>
  );

  const renderTooltipJenkins = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Jenkins
    </Tooltip>
  );

  const renderTooltipKubernetes = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Kubernetes
    </Tooltip>
  );

  const renderTooltipLinux = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Linux
    </Tooltip>
  );

  const renderTooltipCloudflare = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Cloudflare
    </Tooltip>
  );

  const renderTooltipJira = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Jira
    </Tooltip>
  );

  const renderTooltipOpenCart = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      OpenCart
    </Tooltip>
  );

  const renderTooltipAzure = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Microsoft Azure
    </Tooltip>
  );

  const renderTooltipAws = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Amazon Web Services
    </Tooltip>
  );

  const renderTooltipNginx = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Nginx
    </Tooltip>
  );

  const renderTooltipMysql = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      MySQL
    </Tooltip>
  );

  const renderTooltipAdobe = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Adobe Suite
    </Tooltip>
  );

  return (
    <section className="bg-primary-3 text-light">
      <div className="container mt-lg-6">
        <div className="row">
          <div className="col">
            <FadeInWhenVisible>
              <h1 className="display-3" style={{ lineHeight: "1.2" }}>
                I strive to be tech stack agnostic.
              </h1>
              <div className="my-4">
                <p className="h3">
                  Although I have a specific skill set, I still try to adapt to
                  a <span className="text-orange">framework</span> or a{" "}
                  <span className="text-orange">language</span> if needed. At
                  the end of the day, performance and a good UX is all that
                  matters to me.
                </p>
              </div>
              <div className="mt-4 mt-lg-5">
                <div className="container px-0">
                  <div className="row d-flex flex-wrap">
                    <div className="col-4 col-md-2 col-lg-1 my-2">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipJS}
                      >
                        <img
                          role="button"
                          src="images/tech/javascript.svg"
                          alt="JavaScript"
                        />
                      </OverlayTrigger>
                    </div>
                    <div className="col-4 col-md-2 col-lg-1 my-2">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipPhp}
                      >
                        <img
                          role="button"
                          src="images/tech/php.svg"
                          alt="PHP"
                        />
                      </OverlayTrigger>
                    </div>
                    <div className="col-4 col-md-2 col-lg-1 my-2">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipReact}
                      >
                        <img
                          role="button"
                          src="images/tech/react.svg"
                          alt="React"
                        />
                      </OverlayTrigger>
                    </div>
                    <div className="col-4 col-md-2 col-lg-1 my-2">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipNext}
                      >
                        <img
                          role="button"
                          src="images/tech/nextjs.svg"
                          alt="Next.js"
                        />
                      </OverlayTrigger>
                    </div>
                    <div className="col-4 col-md-2 col-lg-1 my-2">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipJQuery}
                      >
                        <img
                          role="button"
                          src="images/tech/jquery.svg"
                          alt="jQuery"
                        />
                      </OverlayTrigger>
                    </div>
                    <div className="col-4 col-md-2 col-lg-1 my-2">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipBootstrap}
                      >
                        <img
                          role="button"
                          src="images/tech/bootstrap.svg"
                          alt="Bootstrap"
                        />
                      </OverlayTrigger>
                    </div>
                    <div className="col-4 col-md-2 col-lg-1 my-2">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipHTML}
                      >
                        <img
                          role="button"
                          src="images/tech/html5.svg"
                          alt="HTML5"
                        />
                      </OverlayTrigger>
                    </div>
                    <div className="col-4 col-md-2 col-lg-1 my-2">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipCSS}
                      >
                        <img
                          role="button"
                          src="images/tech/css3.svg"
                          alt="CSS3"
                        />
                      </OverlayTrigger>
                    </div>
                    <div className="col-4 col-md-2 col-lg-1 my-2">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipIonic}
                      >
                        <img
                          role="button"
                          src="images/tech/ionic.svg"
                          alt="Ionic"
                        />
                      </OverlayTrigger>
                    </div>
                    <div className="col-4 col-md-2 col-lg-1 my-2">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipPython}
                      >
                        <img
                          role="button"
                          src="images/tech/python.svg"
                          alt="Python"
                        />
                      </OverlayTrigger>
                    </div>
                    <div className="col-4 col-md-2 col-lg-1 my-2">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipNpm}
                      >
                        <img
                          role="button"
                          src="images/tech/npm.svg"
                          alt="npm"
                        />
                      </OverlayTrigger>
                    </div>
                    <div className="col-4 col-md-2 col-lg-1 my-2">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipGit}
                      >
                        <img
                          role="button"
                          src="images/tech/git.svg"
                          alt="Git"
                        />
                      </OverlayTrigger>
                    </div>
                    <div className="col-4 col-md-2 col-lg-1 my-2">
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipGitHub}
                      >
                        <img
                          role="button"
                          src="images/tech/github.svg"
                          alt="GitHub"
                        />
                      </OverlayTrigger>
                    </div>
                    <div className="col-4 col-md-2 col-lg-1 my-2">
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipJenkins}
                      >
                        <img
                          role="button"
                          src="images/tech/jenkins.svg"
                          alt="Jenkins"
                        />
                      </OverlayTrigger>
                    </div>
                    <div className="col-4 col-md-2 col-lg-1 my-2">
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipKubernetes}
                      >
                        <img
                          role="button"
                          src="images/tech/kubernetes.svg"
                          alt="Kubernetes"
                        />
                      </OverlayTrigger>
                    </div>
                    <div className="col-4 col-md-2 col-lg-1 my-2">
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipLinux}
                      >
                        <img
                          role="button"
                          src="images/tech/linux.svg"
                          alt="Linux"
                        />
                      </OverlayTrigger>
                    </div>
                    <div className="col-4 col-md-2 col-lg-1 my-2">
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipCloudflare}
                      >
                        <img
                          role="button"
                          src="images/tech/cloudflare.svg"
                          alt="Cloudflare"
                        />
                      </OverlayTrigger>
                    </div>
                    <div className="col-4 col-md-2 col-lg-1 my-2">
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipJira}
                      >
                        <img
                          role="button"
                          src="images/tech/jira.svg"
                          alt="Atlassian Jira"
                        />
                      </OverlayTrigger>
                    </div>
                    <div className="col-4 col-md-2 col-lg-1 my-2">
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipOpenCart}
                      >
                        <img
                          role="button"
                          src="images/tech/opencart.svg"
                          alt="OpenCart"
                        />
                      </OverlayTrigger>
                    </div>
                    <div className="col-4 col-md-2 col-lg-1 my-2">
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipAzure}
                      >
                        <img
                          role="button"
                          src="images/tech/azure.svg"
                          alt="Microsoft Azure"
                        />
                      </OverlayTrigger>
                    </div>
                    <div className="col-4 col-md-2 col-lg-1 my-2">
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipAws}
                      >
                        <img
                          role="button"
                          src="images/tech/aws.svg"
                          alt="AWS Cloud"
                        />
                      </OverlayTrigger>
                    </div>
                    <div className="col-4 col-md-2 col-lg-1 my-2">
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipNginx}
                      >
                        <img
                          role="button"
                          src="images/tech/nginx.svg"
                          alt="Nginx"
                        />
                      </OverlayTrigger>
                    </div>
                    <div className="col-4 col-md-2 col-lg-1 my-2">
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipMysql}
                      >
                        <img
                          role="button"
                          src="images/tech/mysql.svg"
                          alt="MySQL"
                        />
                      </OverlayTrigger>
                    </div>
                    <div className="col-4 col-md-2 col-lg-1 my-2">
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipAdobe}
                      >
                        <img
                          role="button"
                          src="images/tech/adobe.svg"
                          alt="Adobe Suite"
                        />
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
              </div>
            </FadeInWhenVisible>
          </div>
        </div>
      </div>
    </section>
  );
}
