import { Tooltip, OverlayTrigger } from "react-bootstrap";
import image from "./images/xstack-status.png";

export default function xstackStatus() {
  const renderTooltipDanielMark = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Daniel Mark
    </Tooltip>
  );

  const renderTooltipMixSpace = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      MixSpace Internet Services
    </Tooltip>
  );

  const renderTooltipPattarai = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Pattarai
    </Tooltip>
  );

  const renderTooltipLICET = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      LICET
    </Tooltip>
  );

  return (
    <div className="card mt-md-0">
      <a href="https://xstackhub.azurewebsites.net" target="top">
        <img src={image} alt="Image" className="card-img-top" />
      </a>
      <div className="card-body d-flex flex-column">
        <div className="d-flex justify-content-between mb-3">
          <div className="text-small d-flex">
            <div className="mr-2">Enterprise</div>
            <span className="text-muted">
              May 2020 <i className="fas fa-angle-double-right"></i> Jun 2020
            </span>
          </div>
          {/* <span className="badge bg-primary-alt text-primary">
            <a
              href="https://github.com/thedanielmark/dannyWebsite"
              target="top"
            >
              <i className="fab fa-github font-weight-bold"></i>
            </a>
          </span> */}
        </div>
        <a href="https://xstackhub.azurewebsites.net" target="top">
          <h4>
            xStack Status
            <sup className="small ml-1">
              <i className="fas fa-external-link-alt"></i>
            </sup>
          </h4>
        </a>
        <p className="flex-grow-1">
          Website to display the status of all services on the xStack Platform
          in real time. The dashboard also had an API to stream the overall
          availability of the platform to 3rd party sites.
        </p>
        <div className="d-flex align-items-center mt-3">
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltipDanielMark}
          >
            <img
              src="https://avatars.githubusercontent.com/u/26758790?s=460&u=429b89cb22392b1695712e237ef3384fd46df9ea&v=4"
              alt="Image"
              className="avatar avatar-sm"
              role="button"
            />
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltipMixSpace}
          >
            <div className="border rounded-circle ml-1">
              <img
                src="https://avatars.githubusercontent.com/u/69184684?s=460&u=d9fe051b89860905db4a2f1cc180fc00ee3c4572&v=4"
                alt="Image"
                className="avatar avatar-sm"
                role="button"
              />
            </div>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltipPattarai}
          >
            <div className="border rounded-circle ml-1">
              <img
                src="https://avatars.githubusercontent.com/u/67852035?s=200&v=4"
                alt="Image"
                className="avatar avatar-sm"
                role="button"
              />
            </div>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltipLICET}
          >
            <img
              src="https://www.licet.ac.in/wp-content/uploads/2020/10/logo-licet.png"
              alt="Image"
              className="avatar avatar-sm ml-1"
              role="button"
            />
          </OverlayTrigger>
        </div>
      </div>
    </div>
  );
}
