import FadeInWhenVisible from "../../FadeInWhenVisible";
import { Container } from "react-bootstrap";

export default function MainContent() {
  return (
    <FadeInWhenVisible>
      <Container>
        <div className="row">
          <div
            className="col-md-6 col-lg-3 d-flex aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay={100}
          >
            <a
              href="#"
              className="card card-icon-2 card-body justify-content-between hover-shadow-3d"
            >
              <div className="icon-round mb-3 mb-md-4 icon bg-primary">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  className="injected-svg icon bg-primary"
                  data-src="assets/img/icons/theme/general/user.svg"
                >
                  <title>Icon For User</title>
                  <g
                    stroke="none"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                  >
                    <polygon points="0 0 24 0 24 24 0 24" opacity={0} />
                    <path
                      d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                      fill="#000000"
                      fillRule="nonzero"
                      opacity="0.3"
                    />
                    <path
                      d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                      fill="#000000"
                      fillRule="nonzero"
                    />
                  </g>
                </svg>
              </div>
              <h5 className="mb-0">Cloud Hosting through MixSpace</h5>
            </a>
          </div>
          <div
            className="col-md-6 col-lg-3 d-flex aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay={200}
          >
            <a
              href="#"
              className="card card-icon-2 card-body justify-content-between hover-shadow-3d bg-primary text-light"
            >
              <div className="icon-round mb-3 mb-md-4 icon bg-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  className="injected-svg icon bg-white"
                  data-src="assets/img/icons/theme/home/clock.svg"
                >
                  <title>Icon For Clock</title>
                  <g
                    stroke="none"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect opacity={0} x={0} y={0} width={24} height={24} />
                    <path
                      d="M12,22 C7.02943725,22 3,17.9705627 3,13 C3,8.02943725 7.02943725,4 12,4 C16.9705627,4 21,8.02943725 21,13 C21,17.9705627 16.9705627,22 12,22 Z"
                      fill="#000000"
                      opacity="0.3"
                    />
                    <path
                      d="M11.9630156,7.5 L12.0475062,7.5 C12.3043819,7.5 12.5194647,7.69464724 12.5450248,7.95024814 L13,12.5 L16.2480695,14.3560397 C16.403857,14.4450611 16.5,14.6107328 16.5,14.7901613 L16.5,15 C16.5,15.2109164 16.3290185,15.3818979 16.1181021,15.3818979 C16.0841582,15.3818979 16.0503659,15.3773725 16.0176181,15.3684413 L11.3986612,14.1087258 C11.1672824,14.0456225 11.0132986,13.8271186 11.0316926,13.5879956 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z"
                      fill="#000000"
                    />
                  </g>
                </svg>
              </div>
              <h5 className="mb-0">Cross-Platform Mobile Apps</h5>
            </a>
          </div>
          <div
            className="col-md-6 col-lg-3 d-flex aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay={300}
          >
            <a
              href="#"
              className="card card-icon-2 card-body justify-content-between hover-shadow-3d bg-primary-2 text-light"
            >
              <div className="icon-round mb-3 mb-md-4 icon bg-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  className="injected-svg icon bg-white"
                  data-src="assets/img/icons/theme/devices/mic.svg"
                >
                  <title>Icon For Mic</title>
                  <g
                    stroke="none"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect opacity={0} x={0} y={0} width={24} height={24} />
                    <path
                      d="M12.9975507,17.929461 C12.9991745,17.9527631 13,17.9762852 13,18 L13,21 C13,21.5522847 12.5522847,22 12,22 C11.4477153,22 11,21.5522847 11,21 L11,18 C11,17.9762852 11.0008255,17.9527631 11.0024493,17.929461 C7.60896116,17.4452857 5,14.5273206 5,11 L7,11 C7,13.7614237 9.23857625,16 12,16 C14.7614237,16 17,13.7614237 17,11 L19,11 C19,14.5273206 16.3910388,17.4452857 12.9975507,17.929461 Z"
                      fill="#000000"
                      fillRule="nonzero"
                    />
                    <rect
                      fill="#000000"
                      opacity="0.3"
                      transform="translate(12.000000, 8.000000) rotate(-360.000000) translate(-12.000000, -8.000000) "
                      x={9}
                      y={2}
                      width={6}
                      height={12}
                      rx={3}
                    />
                  </g>
                </svg>
              </div>
              <h5 className="mb-0">Enterprise Software Development</h5>
            </a>
          </div>
          <div
            className="col-md-6 col-lg-3 d-flex aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay={400}
          >
            <a
              href="#"
              className="card card-icon-2 card-body justify-content-between hover-shadow-3d bg-primary-4 text-light"
            >
              <div className="icon-round mb-3 mb-md-4 icon bg-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  className="injected-svg icon bg-white"
                  data-src="assets/img/icons/theme/devices/battery-half.svg"
                >
                  <title>Icon For Battery-half</title>
                  <g
                    stroke="none"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect opacity={0} x={0} y={0} width={24} height={24} />
                    <path
                      d="M11,9 L11,15 L17,15 L17,9 L11,9 Z M4,7 L17,7 C18.1045695,7 19,7.8954305 19,9 L19,15 C19,16.1045695 18.1045695,17 17,17 L4,17 C2.8954305,17 2,16.1045695 2,15 L2,9 C2,7.8954305 2.8954305,7 4,7 Z"
                      fill="#000000"
                      fillRule="nonzero"
                    />
                    <path
                      d="M20,10 L21,10 C21.5522847,10 22,10.4477153 22,11 L22,13 C22,13.5522847 21.5522847,14 21,14 L20,14 L20,10 Z"
                      fill="#000000"
                      opacity="0.3"
                    />
                  </g>
                </svg>
              </div>
              <h5 className="mb-0">UI and UX Design</h5>
            </a>
          </div>
        </div>
      </Container>
    </FadeInWhenVisible>
  );
}
