import FadeInWhenVisible from "../../FadeInWhenVisible";

export default function MainContent() {
  return (
    <section className="bg-primary-3 text-light">
      <div className="container mt-lg-6">
        <div className="row">
          <div className="col">
            <FadeInWhenVisible>
              <h1 className="display-3" style={{ lineHeight: "1.2" }}>
                I've got plenty of experience building web apps and writing
                JavaScript &amp; PHP code.
              </h1>
              <div className="my-4">
                <p className="h3">
                  I build awesome stuff for the web at{" "}
                  <a
                    href="https://mixspace.io"
                    className="text-orange"
                    target="top"
                  >
                    MixSpace Internet Services.
                  </a>{" "}
                  Oh and I also run a technical club based out of LICET named{" "}
                  <a
                    href="https://pattarai.in"
                    className="text-orange"
                    target="top"
                  >
                    Pattarai!
                  </a>
                </p>
              </div>
            </FadeInWhenVisible>
          </div>
        </div>
      </div>
    </section>
  );
}
