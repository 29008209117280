import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import FadeInWhenVisible from "../../FadeInWhenVisible";

export default function MainContent() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <section className="bg-primary-3 text-light">
      <div className="container mt-lg-6">
        <div className="row">
          <div className="col">
            <FadeInWhenVisible>
              <h1 className="display-3" style={{ lineHeight: "1.2" }}>
                I love Enterprise Software, Scalable Web Applications, UI/UX
                Design &amp; Cloud Computing.
              </h1>
              <div className="my-4">
                <p className="h3">
                  Apart from that, some of the other things I love and am
                  passionate about are reading, Iron Man, Metallica, start-ups,
                  live sound, tiger cubs and animated platypuses.
                </p>
              </div>
            </FadeInWhenVisible>
            {/* <a href="#" className="btn btn-lg btn-primary">
              Get In Touch
            </a> */}
          </div>
        </div>
      </div>
    </section>
  );
}
