import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "../../input.min.css";

export default function MainContent() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-6">
          <div className="ui input">
            <input type="text" placeholder="Search..." />
          </div>
        </div>
        <div className="col-6"></div>
        <div className="col-6"></div>
        <div className="col-6"></div>
        <div className="col-6"></div>
        <div className="col-6"></div>
        <div className="col-6"></div>
        <div className="col-6"></div>
      </div>
    </div>
  );
}
