import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Layout from "./Layout";
import MainContent from "./components/friends/MainContent";

export default class friendshipApplicaton extends React.Component {
  render() {
    return (
      <HelmetProvider>
        <Helmet>
          <title>Friendship Applicaiton | Daniel Mark</title>
          <meta name="robots" content="noindex" />
        </Helmet>
        <Layout>
          <MainContent></MainContent>
        </Layout>
      </HelmetProvider>
    );
  }
}
