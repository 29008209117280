import { Tooltip, OverlayTrigger } from "react-bootstrap";
import image from "./images/we.PNG";
import weLogo from "./images/we-logo.png";

export default function fringeMessenger() {
  const renderTooltipDanielMark = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Daniel Mark
    </Tooltip>
  );

  const renderTooltipWe = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      We're Everywhere!
    </Tooltip>
  );

  return (
    <div className="card mt-md-0">
      <a href="https://fringe.thedanielmark.com" target="top">
        <img src={image} alt="Image" className="card-img-top" />
      </a>
      <div className="card-body d-flex flex-column">
        <div className="d-flex justify-content-between mb-3">
          <div className="text-small d-flex">
            <div className="mr-2">Venture</div>
            <span className="text-muted">
              Dec 2019 <i className="fas fa-angle-double-right"></i> Jan 2020
            </span>
          </div>
          {/* <span className="badge bg-primary-alt text-primary">
            <a href="https://github.com/thedanielmark/fringesite" target="top">
              <i className="fab fa-github font-weight-bold"></i>
            </a>
          </span> */}
        </div>
        <a href="https://fringe.thedanielmark.com" target="top">
          <h4>
            WE!
            {/* <sup className="small ml-1">
              <i className="fas fa-external-link-alt"></i>
            </sup> */}
          </h4>
        </a>
        <p className="flex-grow-1">
          Personal safety app heavily inspired by Uber. It logged
          user's locations in a database and with the press of a button,
          could send out an SMS notification to the 100 nearest people calling
          for help when a user is under duress.
        </p>
        <div className="d-flex align-items-center mt-3">
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltipDanielMark}
          >
            <img
              src="https://avatars.githubusercontent.com/u/26758790?s=460&u=429b89cb22392b1695712e237ef3384fd46df9ea&v=4"
              alt="Image"
              className="avatar avatar-sm"
              role="button"
            />
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltipWe}
          >
            <div className="border rounded-circle ml-1">
              <img
                src={weLogo}
                alt="Image"
                className="avatar avatar-sm"
                role="button"
              />
            </div>
          </OverlayTrigger>
        </div>
      </div>
    </div>
  );
}
