import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function MainContent() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <section className="">
      <div className="container mt-lg-6">
        <div className="row">
          <div className="col">
            <h1 className="display-3" style={{ lineHeight: "1.2" }}>
              I've worked on various projects over the years.
            </h1>
            <div className="my-4">
              <p className="h3">
                Check out the entire list below. Some of them even have public GitHub repositories!
              </p>
            </div>
            {/* <a href="#" className="btn btn-lg btn-primary">
                Get In Touch
              </a> */}
          </div>
        </div>
      </div>
    </section>
  );
}
