import { Tooltip, OverlayTrigger } from "react-bootstrap";
import image from "./images/radar.png";

export default function radar() {
  const renderTooltipDanielMark = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Daniel Mark
    </Tooltip>
  );

  const renderTooltipLICET = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      LICET
    </Tooltip>
  );

  return (
    <div className="card mt-md-0">
      <a href="https://ghostdetector.thedanielmark.com/" target="top">
        <img src={image} alt="Image" className="card-img-top" />
      </a>
      <div className="card-body d-flex flex-column">
        <div className="d-flex justify-content-between mb-3">
          <div className="text-small d-flex">
            <div className="mr-2">Personal</div>
            <span className="text-muted">
              Nov 2019 <i className="fas fa-angle-double-right"></i> Dec 2019
            </span>
          </div>
          {/* <span className="badge bg-primary-alt text-primary">
            <a
              href="https://github.com/thedanielmark/elevate.pattarai.in"
              target="top"
            >
              <i className="fab fa-github font-weight-bold"></i>
            </a>
          </span> */}
        </div>
        <a href="https://ghostdetector.thedanielmark.com/" target="top">
          <h4>
            RADAR
            <sup className="small ml-1">
              <i className="fas fa-external-link-alt"></i>
            </sup>
          </h4>
        </a>
        <p className="flex-grow-1">
          Built a 1/26 scale model of a RADAR using an infrasonic sensor that
          could detect objects in front of it and project the signals to a graph
          on a computer running a Processing GUI.
        </p>
        <div className="d-flex align-items-center mt-3">
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltipDanielMark}
          >
            <img
              src="https://avatars.githubusercontent.com/u/26758790?s=460&u=429b89cb22392b1695712e237ef3384fd46df9ea&v=4"
              alt="Image"
              className="avatar avatar-sm"
              role="button"
            />
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltipLICET}
          >
            <img
              src="https://www.licet.ac.in/wp-content/uploads/2020/10/logo-licet.png"
              alt="Image"
              className="avatar avatar-sm ml-1"
              role="button"
            />
          </OverlayTrigger>
        </div>
      </div>
    </div>
  );
}
