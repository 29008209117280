import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import FadeInWhenVisible from "./FadeInWhenVisible";

// import "../projects.min.css";

import Layout from "./Layout";
import Intro from "./components/projects/Intro";
import ProjectList from "./components/projects/ProjectList";

export default class Projects extends React.Component {
  render() {
    return (
      <HelmetProvider>
        <Helmet>
          <title>Projects | Daniel Mark</title>
        </Helmet>
        <Layout>
          <FadeInWhenVisible>
            <Intro></Intro>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <ProjectList></ProjectList>
          </FadeInWhenVisible>
        </Layout>
      </HelmetProvider>
    );
  }
}
