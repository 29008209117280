import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

// import "semantic-ui-css/semantic.min.css";
import "./styles-2.css";
import "./custom.css";

// Importing all pages
import Index from "./pages/Index";
import About from "./pages/About";
import Projects from "./pages/Projects";
import FriendshipApplication from "./pages/Friends";
import PrivacyPolicy from "./pages/PrivacyPolicy";
export default class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Index} />
          <Route exact path="/about" component={About} />
          <Route exact path="/projects" component={Projects} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/friendship-application" component={FriendshipApplication} />
          <Redirect to="/404" />
        </Switch>
      </Router>
    );
  }
}
