import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import Layout from "./Layout";
import Landing from "./components/about/Landing";
import StuffIDo from "./components/about/StuffIDo";
import Experience from "./components/about/Experience";
import ExperienceCards from "./components/about/ExperienceCards";
import DesignProcess from "./components/about/DesignProcess";
import Technologies from "./components/about/Technologies";

export default class About extends React.Component {
  render() {
    return (
      <HelmetProvider>
        <Helmet>
          <title>About Me | Daniel Mark</title>
        </Helmet>
        <Layout>
          <Landing></Landing>
          <StuffIDo></StuffIDo>
          <Experience></Experience>
          <ExperienceCards></ExperienceCards>
          <DesignProcess></DesignProcess>
          <Technologies></Technologies>
        </Layout>
      </HelmetProvider>
    );
  }
}
