import { Tooltip, OverlayTrigger } from "react-bootstrap";
import image from "./images/one.png";

export default function one() {
  const renderTooltipDanielMark = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Daniel Mark
    </Tooltip>
  );

  return (
    <div className="card mt-md-0">
      <a href="https://one.thedanielmark.com" target="top">
        <img src={image} alt="Image" className="card-img-top" />
      </a>
      <div className="card-body d-flex flex-column">
        <div className="d-flex justify-content-between mb-3">
          <div className="text-small d-flex">
            <div className="mr-2">Personal</div>
            <span className="text-muted">
              Aug 2019 <i className="fas fa-angle-double-right"></i> Sept 2019
            </span>
          </div>
          <span className="badge bg-primary-alt text-primary">
            <a href="https://github.com/thedanielmark/one" target="top">
              <i className="fab fa-github font-weight-bold"></i>
            </a>
          </span>
        </div>
        <a href="https://one.thedanielmark.com" target="top">
          <h4>
            One
            <sup className="small ml-1">
              <i className="fas fa-external-link-alt"></i>
            </sup>
          </h4>
        </a>
        <p className="flex-grow-1">
          One is the first prototype of a symmetric key cryptographic system I
          designed and built. Fringe Messenger and the BlackBox project are
          based off of this encryption standard.
        </p>
        <div className="d-flex align-items-center mt-3">
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltipDanielMark}
          >
            <img
              src="https://avatars.githubusercontent.com/u/26758790?s=460&u=429b89cb22392b1695712e237ef3384fd46df9ea&v=4"
              alt="Image"
              className="avatar avatar-sm"
              role="button"
            />
          </OverlayTrigger>
        </div>
      </div>
    </div>
  );
}
