import { Tooltip, OverlayTrigger } from "react-bootstrap";
import image from "./images/fringe.png";

export default function fringeMessenger() {
  const renderTooltipDanielMark = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Daniel Mark
    </Tooltip>
  );

  const renderTooltipMixSpace = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      MixSpace Internet Services
    </Tooltip>
  );

  return (
    <div className="card mt-md-0">
      <a href="https://fringe.thedanielmark.com" target="top">
        <img src={image} alt="Image" className="card-img-top" />
      </a>
      <div className="card-body d-flex flex-column">
        <div className="d-flex justify-content-between mb-3">
          <div className="text-small d-flex">
            <div className="mr-2">Personal</div>
            <span className="text-muted">
              Jan 2020 <i className="fas fa-angle-double-right"></i> Mar 2020
            </span>
          </div>
          {/* <span className="badge bg-primary-alt text-primary">
            <a href="https://github.com/thedanielmark/fringesite" target="top">
              <i className="fab fa-github font-weight-bold"></i>
            </a>
          </span> */}
        </div>
        <a href="https://fringe.thedanielmark.com" target="top">
          <h4>
            Fringe Messenger
            <sup className="small ml-1">
              <i className="fas fa-external-link-alt"></i>
            </sup>
          </h4>
        </a>
        <p className="flex-grow-1">
          Instant messaging application that makes use of a
          symmetric key cryptographic system based off of the BlackBox project.
        </p>
        <div className="d-flex align-items-center mt-3">
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltipDanielMark}
          >
            <img
              src="https://avatars.githubusercontent.com/u/26758790?s=460&u=429b89cb22392b1695712e237ef3384fd46df9ea&v=4"
              alt="Image"
              className="avatar avatar-sm"
              role="button"
            />
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltipMixSpace}
          >
            <div className="border rounded-circle ml-1">
              <img
                src="https://avatars.githubusercontent.com/u/69184684?s=460&u=d9fe051b89860905db4a2f1cc180fc00ee3c4572&v=4"
                alt="Image"
                className="avatar avatar-sm"
                role="button"
              />
            </div>
          </OverlayTrigger>
        </div>
      </div>
    </div>
  );
}
