import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import FadeInWhenVisible from "./FadeInWhenVisible";

// Import page sections
import Layout from "./Layout";
import Landing from "./components/index/Landing";
import About from "./components/index/About";
import Stats from "./components/index/Stats";
import WhatIDo from "./components/index/WhatIDo";
import Course from "./components/index/Course";

export default class Index extends React.Component {
  render() {
    return (
      <HelmetProvider>
        <Helmet>
          <title>
            Daniel Mark | Developer Portfolio &amp; Personal Website
          </title>
        </Helmet>
        <Layout>
          <FadeInWhenVisible>
            <Landing></Landing>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <About></About>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <Stats></Stats>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <WhatIDo></WhatIDo>
          </FadeInWhenVisible>
          <Course></Course>
        </Layout>
      </HelmetProvider>
    );
  }
}
