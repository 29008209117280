export default function WhatIDo() {
  return (
    <section className="pt-0">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-11">
            <div className="row">
              <div
                className="col-md-6 d-flex mb-4 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay={100}
              >
                <div className="icon-round bg-primary mr-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    className="injected-svg icon bg-primary"
                    data-src="assets/img/icons/theme/general/shield-protected.svg"
                  >
                    <title>Icon For Shield-protected</title>
                    <g
                      stroke="none"
                      strokeWidth={1}
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect opacity={0} x={0} y={0} width={24} height={24} />
                      <path
                        d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                      <path
                        d="M14.5,11 C15.0522847,11 15.5,11.4477153 15.5,12 L15.5,15 C15.5,15.5522847 15.0522847,16 14.5,16 L9.5,16 C8.94771525,16 8.5,15.5522847 8.5,15 L8.5,12 C8.5,11.4477153 8.94771525,11 9.5,11 L9.5,10.5 C9.5,9.11928813 10.6192881,8 12,8 C13.3807119,8 14.5,9.11928813 14.5,10.5 L14.5,11 Z M12,9 C11.1715729,9 10.5,9.67157288 10.5,10.5 L10.5,11 L13.5,11 L13.5,10.5 C13.5,9.67157288 12.8284271,9 12,9 Z"
                        fill="#000000"
                      />
                    </g>
                  </svg>
                </div>
                <div>
                  <h4 className="mb-2">Authentication</h4>
                  <p className="lead pr-xl-4">
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium.
                  </p>
                </div>
              </div>
              <div
                className="col-md-6 d-flex mb-4 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <div className="icon-round bg-primary mr-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    className="injected-svg icon bg-primary"
                    data-src="assets/img/icons/theme/code/code.svg"
                  >
                    <title>Icon For Code</title>
                    <g
                      stroke="none"
                      strokeWidth={1}
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect opacity={0} x={0} y={0} width={24} height={24} />
                      <path
                        d="M17.2718029,8.68536757 C16.8932864,8.28319382 16.9124644,7.65031935 17.3146382,7.27180288 C17.7168119,6.89328641 18.3496864,6.91246442 18.7282029,7.31463817 L22.7282029,11.5646382 C23.0906029,11.9496882 23.0906029,12.5503176 22.7282029,12.9353676 L18.7282029,17.1853676 C18.3496864,17.5875413 17.7168119,17.6067193 17.3146382,17.2282029 C16.9124644,16.8496864 16.8932864,16.2168119 17.2718029,15.8146382 L20.6267538,12.2500029 L17.2718029,8.68536757 Z M6.72819712,8.6853647 L3.37324625,12.25 L6.72819712,15.8146353 C7.10671359,16.2168091 7.08753558,16.8496835 6.68536183,17.2282 C6.28318808,17.6067165 5.65031361,17.5875384 5.27179713,17.1853647 L1.27179713,12.9353647 C0.909397125,12.5503147 0.909397125,11.9496853 1.27179713,11.5646353 L5.27179713,7.3146353 C5.65031361,6.91246155 6.28318808,6.89328354 6.68536183,7.27180001 C7.08753558,7.65031648 7.10671359,8.28319095 6.72819712,8.6853647 Z"
                        fill="#000000"
                        fillRule="nonzero"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        transform="translate(12.000000, 12.000000) rotate(-345.000000) translate(-12.000000, -12.000000) "
                        x={11}
                        y={4}
                        width={2}
                        height={16}
                        rx={1}
                      />
                    </g>
                  </svg>
                </div>
                <div>
                  <h4 className="mb-2">Developer First</h4>
                  <p className="lead pr-xl-4">
                    Dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna aliqua.
                  </p>
                </div>
              </div>
              <div
                className="col-md-6 d-flex mb-4 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                <div className="icon-round bg-primary mr-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    className="injected-svg icon bg-primary"
                    data-src="assets/img/icons/theme/shopping/box-2.svg"
                  >
                    <title>Icon For Box#2</title>
                    <g
                      stroke="none"
                      strokeWidth={1}
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect opacity={0} x={0} y={0} width={24} height={24} />
                      <path
                        d="M4,9.67471899 L10.880262,13.6470401 C10.9543486,13.689814 11.0320333,13.7207107 11.1111111,13.740321 L11.1111111,21.4444444 L4.49070127,17.526473 C4.18655139,17.3464765 4,17.0193034 4,16.6658832 L4,9.67471899 Z M20,9.56911707 L20,16.6658832 C20,17.0193034 19.8134486,17.3464765 19.5092987,17.526473 L12.8888889,21.4444444 L12.8888889,13.6728275 C12.9050191,13.6647696 12.9210067,13.6561758 12.9368301,13.6470401 L20,9.56911707 Z"
                        fill="#000000"
                      />
                      <path
                        d="M4.21611835,7.74669402 C4.30015839,7.64056877 4.40623188,7.55087574 4.5299008,7.48500698 L11.5299008,3.75665466 C11.8237589,3.60013944 12.1762411,3.60013944 12.4700992,3.75665466 L19.4700992,7.48500698 C19.5654307,7.53578262 19.6503066,7.60071528 19.7226939,7.67641889 L12.0479413,12.1074394 C11.9974761,12.1365754 11.9509488,12.1699127 11.9085461,12.2067543 C11.8661433,12.1699127 11.819616,12.1365754 11.7691509,12.1074394 L4.21611835,7.74669402 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                    </g>
                  </svg>
                </div>
                <div>
                  <h4 className="mb-2">NPM Package</h4>
                  <p className="lead pr-xl-4">
                    Amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua.
                  </p>
                </div>
              </div>
              <div
                className="col-md-6 d-flex mb-4 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay={400}
              >
                <div className="icon-round bg-primary mr-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    className="injected-svg icon bg-primary"
                    data-src="assets/img/icons/theme/weather/cloudy-night.svg"
                  >
                    <title>Icon For Cloudy-night</title>
                    <g
                      stroke="none"
                      strokeWidth={1}
                      fill="none"
                      fillRule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" opacity={0} />
                      <path
                        d="M18.0438023,3.00018787 C17.6184443,3.73557883 17.375,4.58935969 17.375,5.5 C17.375,8.24680825 19.5899426,10.4762889 22.3311977,10.4998121 C21.4667009,11.9944192 19.8507834,13 18,13 C15.2385763,13 13,10.7614237 13,8 C13,5.23857625 15.2385763,3 18,3 C18.0146155,3 18.0292164,3.00006271 18.0438023,3.00018787 Z"
                        fill="#000000"
                        opacity="0.3"
                        transform="translate(17.687500, 8.000000) rotate(-21.000000) translate(-17.687500, -8.000000) "
                      />
                      <path
                        d="M5.74714567,18.0425758 C4.09410362,16.9740356 3,15.1147886 3,13 C3,9.6862915 5.6862915,7 9,7 C11.7957591,7 14.1449096,8.91215918 14.8109738,11.5 L17.25,11.5 C19.3210678,11.5 21,13.1789322 21,15.25 C21,17.3210678 19.3210678,19 17.25,19 L8.25,19 C7.28817895,19 6.41093178,18.6378962 5.74714567,18.0425758 Z"
                        fill="#000000"
                      />
                    </g>
                  </svg>
                </div>
                <div>
                  <h4 className="mb-2">Nightly Releases</h4>
                  <p className="lead pr-xl-4">
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
