import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import MainContent from "./components/privacy-policy/MainContent";
import FadeInWhenVisible from "./FadeInWhenVisible";

// Import page sections
import Layout from "./Layout";

export default class PrivacyPolicy extends React.Component {
  render() {
    return (
      <HelmetProvider>
        <Helmet>
          <title>
            Daniel Mark | Developer Portfolio &amp; Personal Website
          </title>
        </Helmet>
        <Layout>
          <FadeInWhenVisible>
            <MainContent></MainContent>
          </FadeInWhenVisible>
        </Layout>
      </HelmetProvider>
    );
  }
}
